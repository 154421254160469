import { defineStore } from "pinia";

import type {
  IVehicle,
  VehicleYearData,
  VehicleMakeData,
  VehicleModelData,
  VehicleTrimData,
  VehicleYMMTData,
  VehicleOptionData,
  VehicleConfigurationValidationData,
  VehicleDetailByVinData,
} from "@/types/vehicle";

export const FINANCE_TYPE_LOAN = "Loan";
export const FINANCE_TYPE_LEASE = "Lease";
export const FINANCE_TYPE_NO_LOAN = "Neither";
export const FINANCE_TYPE_MANUAL = "Customer Entry";

/**
 * Only state in this store. All reactivity comes from useVehicleModel
 */
export const useVehicleModelStore = defineStore("VehicleModelStore", {
  state: () => ({
    workflowName: <string | null>null,
    customerName: <string | null>null,
    customerEmail: <string | null>null,
    customerPhone: <string | null>null,

    vehicleInstance: <IVehicle>{},

    vehicleYears: <VehicleYearData[]>[],
    vehicleMakes: <VehicleMakeData[]>[],
    vehicleModels: <VehicleModelData[]>[],
    allVehicleTrims: <VehicleTrimData[]>[],
    vehicleDetails: <VehicleYMMTData>{},
    // IMPORTANT!!  vinDecodedVehicleDetails is *ALWAYS* an array of 1 or more vehicles
    vinDecodedVehicleDetails: <VehicleDetailByVinData[]>[], // this is used for filtering the options to be exactly correct
    vehicleAllAvailableOptions: <VehicleOptionData[]>[],

    vehiclePlateDetails: {},
    vinSearchResults: <VehicleDetailByVinData[]>[],

    _vehicleFullConfiguredOptions: <VehicleOptionData[]>[], // reference to the kbb vehicle options that have been selected
    vehicleOptionsValidationResults: <VehicleConfigurationValidationData>{}, // holds the validation results...

    financeType: undefined,
    financeTypes: [FINANCE_TYPE_LOAN, FINANCE_TYPE_LEASE, FINANCE_TYPE_NO_LOAN, FINANCE_TYPE_MANUAL],

    financeTerm: undefined,
    financeMontlyPayment: undefined,
    financeLenderId: undefined,

    financeInquiryType: undefined,
    financeInquiryValue: undefined,
    financePayoffQuoteCustomerConsent: false,

    financeLenders: [],
    financePayoffQuoteDetail: {},

    vehicleValues: {},

    marketValuationId: <string>crypto.randomUUID(),
    selectedMarketValuation: <string | null>null,
  }),

  actions: {
    quoteHasStarted() {
      //bare minimum, we should have a year, make, model
      if (!this.workflowName) return false;
      return (
        (this.vehicleInstance?.vehicle_year && this.vehicleInstance?.vehicle_make && this.vehicleInstance?.vehicle_model) ||
        this.vehicleInstance?.vin
      );
    },
  },
});
